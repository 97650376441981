import React, { useState } from 'react';
import { FormContextValues, Controller } from 'react-hook-form';
import { Box } from 'rebass';
import { Label } from '@rebass/forms';
import { useTheme } from 'emotion-theming';
import Autosuggest from 'react-autosuggest';
import { FieldMessage } from './fieldError';

export interface Option {
  label: string;
  value: string;
}

interface FormAutocompleteProps {
  name: string;
  placeholder?: string;
  form: FormContextValues<any>;
  validation: any;
  options: Option[];
  label?: string;
  selectMessage?: (Option) => string;
}

function FormAutocomplete({
  name,
  placeholder,
  options,
  form,
  validation,
  label,
  selectMessage,
}: FormAutocompleteProps) {
  const { control, setValue, errors } = form;
  return (
    <Box>
      <Label variant={'text.h500Medium'} mb={'12px'}>
        {label}
      </Label>
      <Controller
        control={control}
        name={name}
        selectMessage={selectMessage}
        onChange={e => {
          const selected = e[0];
          setValue(name, selected);
          return selected;
        }}
        rules={validation}
        placeholder={placeholder}
        options={options}
        errorMessage={errors[name]?.message}
        as={<AutocompleteInput />}
      />
    </Box>
  );
}

interface AutocompleteInputProps {
  placeholder?: string;
  options?: any[];
  onChange?: any;
  errorMessage?: string;
  selectMessage?: (Option) => string;
}

function AutocompleteInput({
  placeholder,
  options,
  onChange,
  errorMessage,
  selectMessage,
}: AutocompleteInputProps) {
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedMessage, setSelectedMessage] = useState<string>();
  const theme: any = useTheme();
  return (
    <>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={opt => {
          const inputValue = opt.value.trim().toLowerCase();
          const filtered =
            inputValue.length === 0
              ? []
              : options
                  .filter(
                    sug =>
                      sug.label
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) > -1,
                  )
                  .slice(0, 25);
          setSuggestions(filtered);
        }}
        onSuggestionsClearRequested={() => {
          setSuggestions([]);
        }}
        getSuggestionValue={opt => opt.label}
        renderSuggestion={opt => <div>{opt.label}</div>}
        onSuggestionSelected={(e, { suggestion }) => {
          setSelectedMessage(selectMessage(suggestion));
          onChange(suggestion);
        }}
        inputProps={{
          placeholder,
          value: inputValue,
          onChange: (e, { newValue, method }) => {
            setInputValue(newValue);
            if (method === 'type') {
              onChange({ label: newValue });
            }
            if (newValue.length === 0) {
              setSelectedMessage(null);
              onChange(null);
            }
          },
        }}
        theme={{
          container: {
            position: 'relative',
            outline: 'none',
          },
          containerOpen: {
            outline: 'none',
          },
          input: {
            outline: 'none',
            border: `1px solid black`,
            borderRadius: '72px',
            fontFamily: `${theme?.fonts.body}`,
            fontSize: `${theme?.text.h500Regular.fontSize}`,
            lineHeight: `${theme?.text.h500Regular.lineHeight}`,
            fontWeight: `${theme?.text.h500Regular.fontWeight}`,
            width: '100%',
            paddingLeft: '20px',
            paddingTop: '10px',
            paddingBottom: '10px',
          },
          inputOpen: {
            outline: 'none',
          },
          inputFocused: {
            outline: 'none',
          },
          suggestionsContainer: {
            position: 'relative',
          },
          suggestionsList: {
            maxHeight: '35vh',
            backgroundColor: 'white',
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            position: 'absolute',
            listStyleType: 'none',
            fontFamily: `${theme?.fonts.body}`,
            fontSize: `${theme?.text.h500Regular.fontSize}`,
            lineHeight: `${theme?.text.h500Regular.lineHeight}`,
            fontWeight: `${theme?.text.h500Regular.fontWeight}`,
            overflowY: 'scroll',
          },
          suggestion: {
            cursor: 'pointer',
            padding: '10px 20px',
            margin: 0,
          },
          suggestionHighlighted: {
            backgroundColor: theme?.colors.teal200,
            color: 'white',
          },
        }}
      />
      <FieldMessage
        message={errorMessage || selectedMessage}
        isError={!!errorMessage}
      />
    </>
  );
}
export default FormAutocomplete;
