import React, { useState, useEffect } from 'react';
import { Box, Heading, Flex } from 'rebass';
import { useForm } from 'react-hook-form';
import { useLocation } from '@reach/router';
import { FormButton } from '../button';
import { visor } from '../../protos';
import Form from '../form';
import FormSelect from '../inputs/formSelect';
import clientrpc from '../../api/clientrpc';
import { isIOS, isAndroid } from 'react-device-detect';
import { serverErrorToast } from '../toaster';
import { track } from '../analytics';
import { smartNav } from './utils';
import { ThemeProvider } from 'emotion-theming';
import theme from '../theme';
import { toast } from 'react-toastify';
import { keyframes, css } from '@emotion/core';
import BeatLoader from 'react-spinners/BeatLoader';
import { graphql, StaticQuery } from 'gatsby';
import FormAutocomplete, { Option } from '../inputs/formAutocomplete';

const win = typeof window !== 'undefined' && window;

export const eligibilityEmailPath = 'eligibility/email';

const animate = keyframes`
  0%,100% { opacity: 0 }
  10%,50%,90% { opacity: 1 }
`;

const I_DONT_KNOW = 'I_DONT_KNOW';
const I_DONT_HAVE_A_JOB = 'I_DONT_HAVE_A_JOB';

function Loader({ message }) {
  return (
    <Box height={isIOS ? '65vh' : '75vh'}>
      <Flex height={'100%'} flexDirection={'column'}>
        <Box flex={1} textAlign={'center'} sx={{ position: 'relative' }}>
          <Heading
            width={[1, 1, 0.5]}
            variant={'h700Regular'}
            css={css`
              animation: ${animate} 6s ease-out infinite;
            `}
            sx={{
              position: 'absolute',
              top: '25%',
              left: '50%',
              transform: 'translate(-50%, 0%)',
            }}
          >
            {message}
          </Heading>
        </Box>
        <Box sx={{ position: 'relative' }} textAlign={'center'}>
          <BeatLoader
            css={css`
              position: absolute;
              top: 90%;
              left: 50%;
              transform: translate(-50%, 70%);
            `}
            color={'#05C890'}
          />
        </Box>
      </Flex>
    </Box>
  );
}

const trackEligibility = (title, properties, callback) => {
  track(
    title,
    {
      email: properties.email,
      payrollProvider: properties.primaryPayrollInstitutionToken,
      employer: properties.employer,
      mobilePlatform:
        visor.clientrpc.CreateLeadRequest.MobilePlatform[
          properties.mobilePlatform
        ],
    },
    callback,
  );
};

function EligibilityForm() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allEmployersCsv {
            edges {
              node {
                Company_name
                Payroll_provider
              }
            }
          }
        }
      `}
      render={data => {
        const employerData = data.allEmployersCsv.edges.map(edge => {
          return {
            label: edge.node.Company_name,
            value: edge.node.Payroll_provider,
          };
        });
        return <EligibilityFormComponent employerOptions={employerData} />;
      }}
    />
  );
}

function EligibilityFormComponent({
  employerOptions,
}: {
  employerOptions: Option[];
}) {
  const location = useLocation();
  const form = useForm({
    reValidateMode: 'onChange',
  });
  const { handleSubmit } = form;
  const [submitting, setSubmitting] = useState(false);
  const [showEmployers, setShowEmployers] = useState(false);

  const onSubmit = async data => {
    // @ts-ignore
    const email = location.state?.overlay?.data?.eligibility?.email;
    const employer =
      data.payrollProvider === I_DONT_HAVE_A_JOB
        ? I_DONT_HAVE_A_JOB
        : data.employerPayrollProvider?.label;

    const payrollInstitutionToken = showEmployers
      ? data.employerPayrollProvider?.value
      : data.payrollProvider;

    const req = new visor.clientrpc.CreateLeadRequest();
    req.email = email;
    req.primaryPayrollInstitutionToken = payrollInstitutionToken;
    req.employer = employer;

    if (isIOS) {
      req.mobilePlatform =
        visor.clientrpc.CreateLeadRequest.MobilePlatform.CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS;
    } else if (isAndroid) {
      req.mobilePlatform =
        visor.clientrpc.CreateLeadRequest.MobilePlatform.CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID;
    } else {
      req.mobilePlatform = data.mobilePhone;
    }

    setSubmitting(true);
    try {
      let res = await clientrpc.createLead(req);
      switch (res.eligiblity) {
        case 'alpha':
          if (win !== undefined) {
            const redirect = res?.alpha?.redirectUrl;
            if (redirect) {
              trackEligibility(
                'Landing Page User Submitted Eligiblity Form (ALPHA)',
                { ...req, employer },
                () => {
                  // Omitting `setSubmitting(false)` here to prevent double submitting during redirect
                  win.location.href = res?.alpha?.redirectUrl;
                },
              );
            } else {
              console.warn(
                `Landing page user ${email} did not receive an alpha redirect url`,
              );
              setSubmitting(false);
              serverErrorToast();
            }
          }
          break;
        case 'beta':
          trackEligibility(
            'Landing Page User Submitted Eligiblity Form (BETA)',
            { ...req, employer },
            () => {
              // Automatically request lead download link on behalf of the beta user.
              // This usually works quickly, but don't block the UX on this step.
              setTimeout(async function() {
                try {
                  const req = new visor.clientrpc.SendLeadDownloadLinkRequest();
                  req.email = email;
                  await clientrpc.sendLeadDownloadLink(req);

                  toast.info(`Email sent to ${email || 'your inbox'}!`);
                } catch (error) {
                  console.error(`sendLeadDownloadLink RPC failed: ${error}`);
                  toast.warn(
                    `We’re having trouble sending you an email. Please download our app to continue.`,
                  );
                }
              }, 0);

              smartNav(location, 'eligibility/payboost', { email: email });
              setSubmitting(false);
            },
          );
          break;
        case 'waitlist':
          trackEligibility(
            'Landing Page User Submitted Eligiblity Form (WAITLIST)',
            { ...req, employer },
            () => {
              smartNav(location, 'eligibility/waitlist');
              setSubmitting(false);
            },
          );
          break;
      }
    } catch (ex) {
      serverErrorToast();
      setSubmitting(false);
      return;
    }
  };

  const loadingMessages = [
    `Please wait, we're checking your eligibility...`,
    'You can access up to $250 / month with Payboost',
    'Hold tight, almost there...',
    'Your tax refund can be your personal piggy bank',
  ];

  const [loadingMessage, setLoadingMessage] = useState<string>(null);
  let currentLoadingMessageIndex = 0;

  useEffect(() => {
    if (submitting || true) {
      if (!loadingMessage) {
        setLoadingMessage(loadingMessages[currentLoadingMessageIndex]);
      }
      const interval = setInterval(function() {
        if (currentLoadingMessageIndex === loadingMessages.length - 1) {
          currentLoadingMessageIndex = 0;
        } else {
          currentLoadingMessageIndex += 1;
        }
        setLoadingMessage(loadingMessages[currentLoadingMessageIndex]);
      }, 6000);
      return () => clearInterval(interval);
    }
  }, [submitting]);
  return (
    <ThemeProvider theme={theme}>
      <Box
        width={['100%', '100%', '800px']}
        mx={'auto'}
        display={submitting ? 'block' : 'none'}
      >
        <Loader message={loadingMessage} />
      </Box>
      <Box display={submitting ? 'none' : 'block'}>
        <Form onSubmit={handleSubmit(onSubmit)} sx={{ marginTop: 26, flex: 1 }}>
          <FormSelect
            sx={{ marginBottom: '15px' }}
            form={form}
            validation={{
              required: 'Please select your current payroll provider',
            }}
            label={'1. Who is your current payroll provider?'}
            placeholder={'Select a provider'}
            disabled={submitting}
            name={'payrollProvider'}
            onChange={e => {
              if (e[0].target.value === I_DONT_KNOW) {
                setShowEmployers(true);
              } else {
                setShowEmployers(false);
              }
            }}
            options={[
              { label: 'ADP', value: 'ADP' },
              { label: 'Gusto', value: 'Gusto' },
              { label: 'Kronos', value: 'Kronos' },
              { label: 'OnPay', value: 'OnPay' },
              { label: 'Paychex', value: 'Paychex' },
              { label: 'Paycom', value: 'Paycom' },
              { label: 'Paycor', value: 'Paycor' },
              { label: 'Paylocity', value: 'Paylocity' },
              { label: 'SurePayroll', value: 'SurePayroll' },
              { label: 'UltiPro', value: 'UltiPro' },
              { label: 'Workday', value: 'Workday' },
              { label: `I don't know`, value: I_DONT_KNOW },
              { label: `I don't have a job`, value: I_DONT_HAVE_A_JOB },
              { label: 'Other', value: 'Other' },
            ]}
          />
          {showEmployers ? (
            <>
              <FormAutocomplete
                label={'Who is your current employer?'}
                placeholder={'Find your employer'}
                form={form}
                validation={{
                  required: 'Please select or type in your employer',
                }}
                name={'employerPayrollProvider'}
                options={employerOptions}
                selectMessage={opt =>
                  opt.label && opt.value
                    ? `${opt.label} is using ${opt.value} as its payroll provider!`
                    : null
                }
              />
            </>
          ) : null}
          {isIOS || isAndroid ? null : (
            <FormSelect
              form={form}
              sx={{ marginTop: '15px' }}
              validation={{
                required: 'Please select your mobile phone type',
              }}
              label={'2. What type of mobile phone do you use?'}
              placeholder={'Select one'}
              name={'mobilePhone'}
              disabled={submitting}
              options={[
                {
                  label: 'iPhone',
                  value:
                    visor.clientrpc.CreateLeadRequest.MobilePlatform
                      .CREATE_LEAD_REQUEST_MOBILE_PLATFORM_IOS,
                },
                {
                  label: 'Android',
                  value:
                    visor.clientrpc.CreateLeadRequest.MobilePlatform
                      .CREATE_LEAD_REQUEST_MOBILE_PLATFORM_ANDROID,
                },
              ]}
            />
          )}

          <FormButton loading={submitting}>Submit</FormButton>
        </Form>
      </Box>
    </ThemeProvider>
  );
}

export default EligibilityForm;
