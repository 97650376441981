import React from 'react';
import { Box, BoxProps } from 'rebass';
import { Select, Label } from '@rebass/forms';
import FieldError from './fieldError';
import { Controller, FormContextValues } from 'react-hook-form';

interface FormSelectProps {
  placeholder: string;
  label: string;
  validation: any;
  name: string;
  disabled: boolean;
  form: FormContextValues;
  options: FormSelectOption[];
}

export interface FormSelectOption {
  label: string;
  value: any;
}

const FormSelect = (props: FormSelectProps & Select & BoxProps) => {
  const {
    placeholder,
    label,
    validation,
    name,
    form,
    disabled,
    options,
    sx,
    onChange,
  } = props;
  const { control } = form;
  return (
    <Box sx={sx}>
      <Controller
        control={control}
        name={name}
        onChange={onChange}
        as={
          <MySelect
            name={name}
            form={form}
            label={label}
            placeholder={placeholder}
            disabled={disabled}
            options={options}
          />
        }
        rules={validation}
      ></Controller>
    </Box>
  );
};

const MySelect = props => {
  const {
    name,
    label,
    form,
    placeholder,
    children,
    disabled,
    options,
    onChange,
  } = props;
  const { setValue, errors } = form;
  return (
    <>
      <Label variant={'text.h500Medium'} mb={'12px'}>
        {label}
      </Label>
      <Select
        disabled={disabled}
        name={name}
        variant={'text.h400Medium'}
        pl={20}
        sx={{
          borderRadius: '72px',
          outline: 'none',
        }}
        onChange={e => {
          onChange(e);
          setValue(name, e.target.value, true);
        }}
      >
        <option disabled selected value={''}>
          {placeholder}
        </option>
        {options.map(o => (
          <option key={o.label} value={o.value}>
            {o.label}
          </option>
        ))}
        {children}
      </Select>
      <FieldError message={errors[name]?.message} />
    </>
  );
};

export default FormSelect;
